import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getDatabase, push, ref } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC9cSuRNNsAbcsmio-eOd9WPx-hxIMT1Ds",
  authDomain: "simple-push-3985f.firebaseapp.com",
  projectId: "simple-push-3985f",
  storageBucket: "simple-push-3985f.appspot.com",
  databaseURL: "https://simple-push-3985f-default-rtdb.firebaseio.com",
  messagingSenderId: "491939882091",
  appId: "1:491939882091:web:c6a170d6e7d0f618f33d2e"
};

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const app = initializeApp(firebaseConfig);

      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey:
          "BNl97DimAYoXDQ1U8tp2QStVtMpTuI3KBYWg-aKcGJbcOuWLpQbrEMQlI74ime5vWhpRc-6UGRqHZXKYuNRkn64",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("currentToken: ", currentToken);

          const db = getDatabase();
          push(ref(db, '/tokens'), {
            token: currentToken
          });

        } else {
          console.log("Can not get token");
        }
      });
    } else {
      console.log("Do not have permission!");
    }
  });
}

requestPermission();
